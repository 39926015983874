import atresaba from '../assets/atresaba.jpg'
import gameoflife from '../assets/gameoflife.jpg'
import greenhouse from '../assets/greenhouse.jpg'
import handpaint from '../assets/handpaint.jpg'
import manshoor from '../assets/manshoor.jpg'
import wifiswitch from '../assets/wifiswitch.jpg'
import timer from '../assets/timer.jpg'
import yektik from '../assets/yektik.jpg'
import ketab from '../assets/ketab.jpg'

export const images = {
    atresaba,
    gameoflife,
    greenhouse,
    handpaint,
    manshoor,
    wifiswitch,
    timer,
    yektik,
    ketab,
}